<template>
  <div>
    <div class="flex flex-col text-14 mb-5 mt-2 font-bold">
      <h2 class="text-2xl font-bold">Rates</h2>
    </div>

    <div class="border border-solid border-blue-200">
      <datatable
        :data="rates.data"
        :columns="rates.columns"
        :query="rates.query"
        :loading="rates.loading"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/invest/admin/rates`"
        :on-click="click"
        ref="table"
      >
      </datatable>
    </div>
  </div>
</template>

<script>
export default {
  props: {
  },
  data() {
    return {
      checkmark: require('@/assets/checkmark-base.svg'),
      currentFilter: null,
      rates: this.$options.resource([], {
        query: '',
        filters: [
          { name: 'today', title: 'Today' },
          { name: 'yesterday', title: 'Yesterday' },
          { name: 'weekly', title: 'Past 7 Days' },
          { name: 'monthly', title: 'Past Month' },
        ],
        selected: null,
        columns: [
          {
            name: 'min_amount',
            th: 'Min Amount',
            render: (rate, amount) => `₦ ${this.$options.filters.currency(amount)}`
          },
          {
            name: 'max_amount',
            th: 'Max Amount',
            render: (rate, amount) => `₦ ${this.$options.filters.currency(amount)}`
          },
          {
            name: 'days_30',
            th: '30 (days)',
            render: rate => {
              let ratex = rate.rate.find(x => x.days == 30);
              return ratex.percentage;
            }
          },
          {
            name: 'days_60',
            th: '60 (days)',
            render: rate => {
              let ratex = rate.rate.find(x => x.days == 60);
              return ratex.percentage;
            }
          },
          {
            name: 'days_90',
            th: '90 (days)',
            render: rate => {
              let ratex = rate.rate.find(x => x.days == 90);
              return ratex.percentage;
            }
          },
          {
            name: 'days_120',
            th: '120 (days)',
            render: rate => {
              let ratex = rate.rate.find(x => x.days == 120);
              return ratex.percentage;
            }
          },
          {
            name: 'days_150',
            th: '150 (days)',
            render: rate => {
              let ratex = rate.rate.find(x => x.days == 150);
              return ratex.percentage;
            }
          },
          {
            name: 'days_180',
            th: '180 (days)',
            render: rate => {
              let ratex = rate.rate.find(x => x.days == 180);
              return ratex.percentage;
            }
          },
          {
            name: 'days_210',
            th: '210 (days)',
            render: rate => {
              let ratex = rate.rate.find(x => x.days == 210);
              return ratex.percentage;
            }
          },
          {
            name: 'days_240',
            th: '240 (days)',
            render: rate => {
              let ratex = rate.rate.find(x => x.days == 240);
              return ratex.percentage;
            }
          },
          {
            name: 'days_270',
            th: '270 (days)',
            render: rate => {
              let ratex = rate.rate.find(x => x.days == 270);
              return ratex.percentage;
            }
          },
          {
            name: 'days_300',
            th: '300 (days)',
            render: rate => {
              let ratex = rate.rate.find(x => x.days == 300);
              return ratex.percentage;
            }
          },
          {
            name: 'days_330',
            th: '330 (days)',
            render: rate => {
              let ratex = rate.rate.find(x => x.days == 330);
              return ratex.percentage;
            }
          },
          {
            name: 'days_306',
            th: '360 (days)',
            render: rate => {
              let ratex = rate.rate.find(x => x.days == 360);
              return ratex.percentage;
            }
          }
        ],
      }),
      form: this.$options.basicForm(['status']),
    };
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(defaultUser) {
      this.$router.push({
        name: "staff-view",
        params: { staffId: defaultUser?.user_id }
      });
    },
    reloadData() {
      this.$refs.table.loadAjaxData();
    },
    setFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null
      } else {
        this.currentFilter = filter
      }

      this.$refs.table.clickedFilter(this.currentFilter)
    },
  }
};
</script>
